import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  MenuItem,
  Box,
  Container,
  Menu,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import Logo from "./../../component/Logo";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ConnectWalletModal from "src/component/ConnectWalletModal";
import { UserContext } from "src/context/User";
import { useWeb3React } from "@web3-react/core";
import { sortAddress } from "src/utils";
import { withStyles } from "@material-ui/core/styles";
import { nativeTokenName, addTokenhandler } from "src/constants";
import { toast } from "react-toastify";
import KYCCheckModal from "src/component/KycModal";
const headersData = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "Marketplace",
    href: "/marketplace",
  },
  {
    label: `About ${nativeTokenName}`,
    href: "/about-momg",
  },
  {
    label: "AI Platform",
    href: "/ai-platform",
  },
  {
    label: "Nodes",
    href: "/nodes",
  },
  // {
  //   label: "OTT Platform",
  //   href: "/ott-platform",
  // },
  {
    label: "Live Concerts",
    href: "/live-concerts",
  },
  // {
  //   label: "Dashboard",
  //   href: "/concert-management",
  // },
];
const loginHeadersData = [
  {
    label: "Profile",
    href: "/my-profile",
  },
  {
    label: "Create NFT",
    href: "/create-nft",
  },
  {
    label: "KYC",
    href: "/kyc",
  },
];
const adminRoute = [
  {
    label: "Dashboard",
    href: "/concert-management",
  },
];

const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: "400",
    borderRadius: 0,
    minWidth: "auto",
    color: "#FFFFFF",
    fontFamily: "'Ubuntu', sans-serif",
    textDecoration: "none",
    height: "31px",
    padding: "0px 7px",
    letterSpacing: "1px",
    marginLeft: "15px",
    "@media (max-width: 900px)": {
      fontStyle: "'Ubuntu', sans-serif",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#FFF",
      padding: "15px !important",
      height: "51px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "&.active": {
      color: "#F5C843",
    },
    "&:hover": {
      color: "#F5C843",
    },
  },
  menuMobile: {
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: "400",
    borderRadius: 0,
    minWidth: "auto",
    color: "#FFFFFF",
    fontFamily: "'Ubuntu', sans-serif",
    textDecoration: "none",
    height: "31px",
    padding: "0px 7px",
    letterSpacing: "1px",
    marginLeft: "15px",
    "@media (max-width: 900px)": {
      padding: "5px 15px !important",
      minHeight: "36px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "&.active": {
      color: "#F5C843",
    },
    "&:hover": {
      color: "#F5C843",
    },
  },
  toolbar: {
    display: "flex",
    padding: "10px 0",
    justifyContent: "space-between",
    height: "100%",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
  },
  logoDrawer: {
    maxWidth: "60px",
    width: "100%",
    // margin: "0 auto",
    paddingLeft: "24px",
    paddingBottom: "14px",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: "#0C0C0C",
    width: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    "& a": {
      width: "100%",
      padding: "5px 0px",
      textDecoration: "none",
    },
  },
  drawericon: {
    color: "#000",
    position: "absolute",
    top: "0px",
    right: "0px",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    // height: '44.5px',
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },
  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
    [theme.breakpoints.down("md")]: {
      padding: "8px",
    },
  },

  wallet: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "21px",
    marginLeft: "15px",
    "@media (max-width: 900px)": {
      marginLeft: "12px",
      marginTop: "12px",
    },
  },

  submenu: {
    borderTop: "3px solid #FFFFFF",
    top: "25px !important",
  },
}));

export default function Header() {
  const {
    menuMobile,
    menuButton,
    menuButton1,
    divstake,
    toolbar,
    drawerContainer,
    drawericon,
    logoDrawer,
    containerHeight,
    mainHeader,
    wallet,
    submenu,
  } = useStyles();
  const { account } = useWeb3React();
  const history = useHistory();
  const location = useLocation();
  const user = useContext(UserContext);
  console.log(" ------- user?.contractOwner?.toLowerCase() ", user?.contractOwner?.toLowerCase())
  const [openErrorKYC, setOpenErrorKYC] = useState(false);
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const [anchorEl1, setAnchorEl1] = React.useState(null);

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose4 = () => {
    setAnchorEl1(null);
  };

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const StyledMenu = withStyles({
    paper: {
      border: " 1px solid #f5c843",
      marginTop: "1px",
      minWidth: "145px",
      marginLeft: "1px",
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));
  const addNativeTokenHadnler = async () => {
    const addToken = await addTokenhandler();
    handleClose4();
  };
  const displayDesktop = () => {
    return (
      <Container maxWidth="lg" className="p-0">
        <Toolbar className={toolbar}>
          { history.location.pathname == "/nodes" ? femmecubatorLogo2 : femmecubatorLogo}
          <Grid
            container
            item
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ paddingLeft: "0px" }}
          >
            {getMenuButtons()}

            {account ? (
              <Button
                className={wallet}
                onClick={handleClick1}
                variant="outlined"
                color="primary"
              >
                {sortAddress(account)}
              </Button>
            ) : (
              <Button
                className={wallet}
                onClick={() => user.setIsOpenConnectWallet(true)}
                variant="outlined"
                color="primary"
              >
                Connect wallet
              </Button>
            )}

            <Box className={divstake}>
              <StyledMenu
                id="simple-menu"
                disableScrollLock={true}
                anchorEl={anchorEl1}
                keepMounted
                open={Boolean(anchorEl1)}
                onClose={handleClose4}
              >
                <MenuItem
                  style={{ fontSize: "14px" }}
                  onClick={() => {
                    history.push("/my-profile");
                  }}
                >
                  Profile
                </MenuItem>
                <MenuItem
                  style={{ fontSize: "14px" }}
                  onClick={() => {
                    if (user?.userData?.approveStatus === "APPROVED") {
                      history.push("/create-nft");
                    } else {
                      setOpenErrorKYC(true);
                    }
                  }}
                >
                  Create NFT
                </MenuItem>
                {user?.userData?.approveStatus === "PENDING" ||
                  user?.userData?.approveStatus === "REJECTED" ||
                  (user?.userData?.approveStatus === "NOT_APPLIED" && (
                    <MenuItem
                      style={{ fontSize: "14px" }}
                      onClick={() => history.push("/kyc")}
                    >
                      KYC
                    </MenuItem>
                  ))}

                <MenuItem
                  style={{ fontSize: "14px" }}
                  onClick={addNativeTokenHadnler}
                >{`Add ${nativeTokenName}`}</MenuItem>
                <MenuItem
                  style={{ fontSize: "14px" }}
                  onClick={() => user.disconnectWallet()}
                >
                  Disconnect
                </MenuItem>
              </StyledMenu>
            </Box>
          </Grid>
        </Toolbar>
      </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <img className={logoDrawer} src="images/logo.png" alt="" />
            {getDrawerChoices()}
            {account ? (
              <Button className={wallet} variant="outlined" color="primary">
                {sortAddress(account)}
              </Button>
            ) : (
              <Button
                className={wallet}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={() => user.setIsOpenConnectWallet(true)}
                variant="outlined"
                color="primary"
              >
                Connect Wallet
              </Button>
            )}
          </div>
        </Drawer>

        <div>{femmecubatorLogo}</div>
        <Grid container>
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <IconButton
              className={drawericon}
              {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                width="60px"
                height="60px"
                style={{ color: "#F5C843", fontSize: "30px" }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    const checkArray = account
      ? headersData.concat(loginHeadersData)
      : headersData;
    const isAdmin =
      account?.toLowerCase() === user?.contractOwner?.toLowerCase()
        ? checkArray.concat(adminRoute)
        : checkArray;
    return isAdmin.map(({ label, href }) => {
      return (
        <>
          <NavLink
            activeClassName="active"
            {...{
              key: label,
              color: "inherit",
              to: href,
              className: menuButton1,
            }}
          >
            <MenuItem className={menuMobile}>{label}</MenuItem>
          </NavLink>
        </>
      );
    });
  };

  const femmecubatorLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );
  const femmecubatorLogo2 = (
    <Box>
      <Link to="/">
      <img
      src="/images/logo2.png"
      alt="Logo"
      style={{ width: "100%", maxWidth: "142px" }}
    />
      </Link>
    </Box>
  );

  const getMenuButtons = () => {
    const isAdmin =
      account?.toLowerCase() === user?.contractOwner?.toLowerCase()
        ? headersData.concat(adminRoute)
        : headersData;
    return isAdmin.map(({ label, href }) => {
      return (
        <>
          <NavLink
            activeClassName="active"
            exact="true"
            {...{
              key: label,
              color: "inherit",
              to: href,
              className: menuButton,
            }}
          >
            {label}
          </NavLink>
        </>
      );
    });
  };

  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        elevation={0}
        style={{ backgroundColor: "#ccc0", border: "none" }}
      >
        <Box
          maxWidth={history.location.pathname !== "/" ? "lg" : "fixed"}
          className={containerHeight}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Box>
      </AppBar>
      <ConnectWalletModal
        user={user}
        openModal={user?.isOpenConnectWallet}
        handleCloseModal={() => user.setIsOpenConnectWallet(false)}
      />
      <KYCCheckModal
        user={user}
        open={openErrorKYC}
        close={() => setOpenErrorKYC(false)}
      />
    </>
  );
}
