import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import DashboardLayout from "src/layouts/DashboardLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    path: "/nft-detail",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/NftDetail")),
  },
  {
    exact: true,
    path: "/my-profile",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/MyProfile")),
  },
  {
    // guard: true,
    exact: true,
    path: "/edit-profile",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/MyProfile/EditProfile")),
  },

  {
    exact: true,
    path: "/create-nft",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/CreateNft")),
  },
  {
    exact: true,
    path: "/user-profile",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Author/UserProfile")),
  },
  {
    exact: true,
    path: "/marketplace",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/MarketPlace")),
  },
  {
    exact: true,
    path: "/ai-platform",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/AIPlatform")),
  },
  {
    exact: true,
    path: "/ott-platform",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/OttPlatform")),
  },
  {
    exact: true,
    path: "/about-momg",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/AboutMbit/AboutToken")),
  },
  {
    exact: true,
    path: "/nodes",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/NodesPage/NodesEco")),
  },
  {
    exact: true,
    path: "/terms-conditions",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/StaticPages/TermAndConditions")
    ),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/StaticPages/PrivacyAndPolicy")
    ),
  },
  {
    exact: true,
    path: "/aml",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/StaticPages/AntiMoneyLaundering")
    ),
  },
  {
    exact: true,
    path: "/kyc-rules",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/StaticPages/KycRules")
    ),
  },
  {
    exact: true,
    path: "/about-us",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticPages/AboutUs")),
  },
  {
    exact: true,
    path: "/live-concerts",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/LiveConcert/LiveMusic")),
  },
  {
    exact: true,
    path: "/card-details",
    layout: HomeLayout,
    component: lazy(() => import("src/component/CardDetails")),
  },
  {
    exact: true,
    path: "/liveconcert-details",
    layout: HomeLayout,
    component: lazy(() => import("src/component/LiveConcertDetail")),
  },
  {
    exact: true,
    path: "/wellbeing-details",
    layout: HomeLayout,
    component: lazy(() => import("src/component/WellBeingDetail")),
  },

  // -------------Admin Routs------------ //
  {
    exact: true,
    path: "/concert-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/ConcertsManagement/ConcertList")
    ),
  },
  {
    exact: true,
    path: "/create-concert",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/ConcertsManagement/CreateConcert")
    ),
  },
  {
    exact: true,
    path: "/view-concert",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/ConcertsManagement/ViewConcertList")
    ),
  },
  {
    exact: true,
    path: "/wellbeing-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/WellBeingManagement/WellBeing")
    ),
  },
  {
    exact: true,
    path: "/create-wellbeing",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/WellBeingManagement/CreateWell")
    ),
  },
  {
    exact: true,
    path: "/view-wellbeing",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/WellBeingManagement/ViewWellBeing")
    ),
  },
  {
    exact: true,
    path: "/user-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/UserManagement/UserData")
    ),
  },
  {
    exact: true,
    path: "/view-user",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/UserManagement/ViewUser")
    ),
  },
  {
    exact: true,
    path: "/feedback-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/FeedbackManagement/FeedbackFilter")
    ),
  },
  {
    exact: true,
    path: "/view-feedback",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/FeedbackManagement/ViewFeedback")
    ),
  },
  {
    exact: true,
    path: "/admin-control",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/Control/AdminControl")
    ),
  },
  {
    exact: true,
    path: "/static-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/StaticManagement/StaticData")
    ),
  },
  {
    exact: true,
    path: "/edit-static",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/StaticManagement/EditStaticData")
    ),
  },
  {
    exact: true,
    path: "/terms-conditions-admin",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/StaticPages/TermAndConditions")
    ),
  },
  {
    exact: true,
    path: "/privacy-policy-admin",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/StaticPages/PrivacyAndPolicy")
    ),
  },
  {
    exact: true,
    path: "/about-momg-admin",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/StaticManagement/AboutMbit")
    ),
  },
  {
    exact: true,
    path: "/kyc",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/KYC/index")),
  },
  {
    exact: true,
    layout: DashboardLayout,
    path: "/kyc-management",
    component: lazy(() =>
      import("src/views/pages/AdminSection/KYCmanagement/KycAddlist")
    ),
  },
  {
    exact: true,
    layout: DashboardLayout,
    path: "/view-kycdetails",
    component: lazy(() =>
      import("src/views/pages/AdminSection/KYCmanagement/ViewKyc")
    ),
  },
  {
    exact: true,
    layout: DashboardLayout,
    path: "/nft-verification",
    component: lazy(() =>
      import("src/views/pages/AdminSection/NftVerification/index")
    ),
  },
  {
    exact: true,
    layout: DashboardLayout,
    path: "/view-nft",
    component: lazy(() =>
      import("src/views/pages/AdminSection/NftVerification/ViewNft")
    ),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
