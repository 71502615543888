import detectEthereumProvider from "@metamask/detect-provider";
export const NetworkContextName = "NETWORK";
// export const ACTIVE_NETWORK = 97;  ///testNet
export const ACTIVE_NETWORK = 56; ///main net

// export const RPC_URL = "https://data-seed-prebsc-2-s1.binance.org:8545/";  ///testnet
export const RPC_URL = "https://bsc-rpc.publicnode.com/"; ///mainnet

// export const marketplaceContractAddress =
//   "0xa38c2913dC2562c3BE58eCC108f7cAEC86536e62"; //testnet
export const marketplaceContractAddress =
  "0x58b95Db45141FfE3B7B4a37f307E3f68915dd8c4"; //mainnet

// export const nativeTokenAddress = "0xDff1538463e5D154492e4d38839abDe2c7533Cd8"; ////testnet
export const nativeTokenAddress = "0x3f614d3f934c2a418d1c59852ec9fc57df0f1fe7"; ////mainnet

export const nativeTokenName = "MOMG";

// testnet----------------
// export const NetworkDetails = [
//   {
//     chainId: "0x61",
//     chainName: "BSC-Testnet",
//     nativeCurrency: {
//       name: "BSC-Testnet",
//       symbol: "TBNB",
//       decimals: 18,
//     },
//     rpcUrls: [RPC_URL],
//     blockExplorerUrls: ["https://bscscan.com"],
//   },
// ];
////mainnet
export const NetworkDetails = [
  {
    chainId: "0x38",
    chainName: "BNB Smart Chain Mainnet",
    nativeCurrency: {
      name: "BNB Smart Chain Mainnet",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: [RPC_URL],
    blockExplorerUrls: ["https://bscscan.com"],
  },
];
export const addTokenhandler = async (event) => {
  const provider = await detectEthereumProvider();
  const isAdded = provider.sendAsync(
    {
      method: "metamask_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: nativeTokenAddress,
          // symbol: nativeTokenName, ////testnet
          symbol: "MOM COIN",   ////mainnet

          decimals: 18,
          image: "",
        },
      },
      id: Math.round(Math.random() * 100000),
    },
    (err, added) => {
      if (added.result) {
        return true;
      } else {
        return false;
      }
    }
  );
  return isAdded;
};
