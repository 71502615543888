import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  makeStyles,
  DialogContent,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import ConnectWalletModal from "src/component/ConnectWalletModal";

const useStyles = makeStyles((theme) => ({
  centerDiv: {
    width: "100%",
    display: "flex",
    alignItemsL: "center",
    justifyContent: "center",
    "& .details": {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      "& h5": {
        marginTop: "5px",
        marginBottom: "30px",
      },
    },
    "& img": {
      maxWidth: "250px",
      [theme.breakpoints.down("xs")]: {
        maxWidth: "110px",
      },
    },
  },
  dialogPaper: {
    padding: "2.5px",
    backgroundImage:
      "radial-gradient(54.8% 53% at 50% 50%, #ffeb3b 0%, rgba(245, 200, 67, 0) 100%), radial-gradient(60% 51.57% at 50% 50%, #F5C843 0%, rgb(255 191 0 / 2%) 100%),radial-gradient(69.43% 69.43% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)",
    "& .root": {
      padding: "15px",
      position: "relative",
      // background: "linear-gradient(152.97deg, rgb(31 31 31) 0%, rgb(0 0 0) 100%)",
      borderRadius: "15px",
      backdropFilter: "blur(21px)",
      backgroundColor: "#000000",
      overflow: "auto",
    },
  },
}));

export default function KYCCheckModal({ open, close, user }) {
  const classes = useStyles();
  const history = useHistory();
  const { account } = useWeb3React();
  const [openModal, setOpenModal] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  function kycMessage(state) {
    switch (state) {
      case "NOT_APPLIED":
        return "Please complete your KYC";
      case "REJECTED":
        return "Your KYC request has been rejected by the admin.";
      case "APPROVED":
        return "";
      case "PENDING":
        return "Your KYC request is in under review.";
    }
  }
  return (
    <>
      <Dialog
        PaperProps={{ classes: { root: classes.dialogPaper } }}
        open={open}
        onClose={close}
        fullWidth
        maxWidth="xs"
      >
        <Box className="root">
          <DialogContent>
            <Box className={classes.centerDiv}>
              <img src="/images/alert@3x.png" alt="" width="100%" />
            </Box>
          </DialogContent>
          <DialogActions>
            <Box className={classes.centerDiv}>
              <Box className="details">
                {account ? (
                  <>
                    <Typography variant="h4">{`${
                      user?.userData?.approveStatus === "NOT_APPLIED"
                        ? "Incomplete"
                        : user?.userData?.approveStatus === "PENDING"
                        ? "Pending"
                        : "Rejected"
                    } KYC`}</Typography>
                    <Typography variant="h5">
                      {kycMessage(user?.userData?.approveStatus)}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Box mb={2}>
                      <Typography variant="h4" style={{ fontSize: "16px" }}>
                        Please connect your metamask
                      </Typography>
                    </Box>
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onClick={() => user.setIsOpenConnectWallet(true)}
                      className={classes.rightButton}
                    >
                      Connect Wallet
                    </Button>
                  </>
                )}

                {user?.userData?.approveStatus === "REJECTED" && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => history.push("/kyc")}
                    fullWidth
                    style={{ marginBottom: "12px" }}
                  >
                    Re-apply
                  </Button>
                )}
                {user?.userData?.approveStatus === "NOT_APPLIED" && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => history.push("/kyc")}
                    fullWidth
                    style={{ marginBottom: "12px" }}
                  >
                    Add KYC
                  </Button>
                )}

                <Box mt={2}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={close}
                    fullWidth
                    style={{ minWidth: "222px" }}
                  >
                    Close
                  </Button>
                </Box>
              </Box>
            </Box>
          </DialogActions>
        </Box>
      </Dialog>
      <ConnectWalletModal
        openModal={openModal}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
}
