import React, { useContext, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import Rating from "@material-ui/lab/Rating";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Container,
  makeStyles,
  Paper,
  FormHelperText,
} from "@material-ui/core";
import axios from "axios";
import * as yup from "yup";
import { toast } from "react-toastify";
import Apiconfig from "src/ApiConfig/ApiConfig";

import { useWeb3React } from "@web3-react/core";
import { Link, useHistory, useLocation } from "react-router-dom";

import ButtonCircularProgress from "src/component/ButtonCircularProgress";

const useStyles = makeStyles((theme) => ({
  mainbox: {
    "& h2": {
      color: "#9b41a1",
    },
    "& label": {
      color: "#FFA701",
      padding: "0",
      fontSize: "50px",
      marginTop: "11px",
      lineHeight: "33px",
      transition:
        "opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px",
      },
    },
  },
  maintext: {
    "& .MuiRating-iconEmpty": {
      color: theme.palette.primary.main,
    },
    "& .ratingField": {
      textAlign: "left",
      marginBottom: "16px",
      padding: "0px",
      "& h3": {
        fontWeight: "400",
        lineHeight: "31px",
        [theme.breakpoints.down("xs")]: {
          fontSize: "18px",
        },
      },
    },
    MuiButton: {
      contained: {
        "&:disabled": {
          color: "rgb(101 85 85 / 26%) !important",
          backgroundColor: "rgb(79 64 64 / 12%)",
        },
      },
    },
  },
}));

const FeedBack = ({props,setOpenModal}) => {
  const { account } = useWeb3React();
  console.log("account----", account);
  const [value, setValue] = React.useState();
  // const { data } = props;

  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const [isUpdating, setIsUpdating] = useState(false);
  const accessToken = window.sessionStorage.getItem("token");

  const formInitialSchema = {
    comment: "",
    rating: "",
    orderId: "",
  };
  const formValidationSchema = yup.object().shape({
    comment: Yup.string()
      .required("Please enter message field")
      .max(300, "Message should not exceed more that 300 character"),
  });

  const handleFormSubmit = async (values) => {
    setIsUpdating(true);
    const formData = new FormData()
    formData.append('message', values.comment)
    formData.append('rating', value)
    axios({
      method: "POST",
      url: Apiconfig.addFeedbacknrating,
      headers: {
        token: sessionStorage.getItem("token"),
      },
      data: formData,
    })
      .then(async (res) => {
        setIsUpdating(false);
        if (res.data.responseCode === 200) {
          history.push("/");
          setOpenModal(false)
          toast.success("Your feedback submitted successfully");
          setIsUpdating(false);
        } else {
          toast.error(res.data.responseMessage);
          setIsUpdating(false);
        }
      })
      .catch(() => {
        setIsUpdating(false);
        
      });
  };

  return (
    <Box className={classes.mainbox}>
      <Formik
        initialValues={formInitialSchema}
        initialStatus={{
          success: false,
          successMsg: "",
        }}
        validationSchema={formValidationSchema}
        onSubmit={(values) => handleFormSubmit(values)}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Paper elevation={2} className="borderGraditant">
              <Box className="backgroudBlack">
                <Box className={classes.maintext}>
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box
                        component="fieldset"
                        borderColor="transparent"
                        align="center"
                        className="ratingField"
                      >
                        <Typography variant="h3" color="primary">
                          Please Rate Your Experience
                        </Typography>

                        <Rating
                          name="simple-controlled"
                          value={value}
                          onChange={(event, newValue) => {
                            setValue(newValue);
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box style={{ marginTop: "-34px" }}>
                        <Typography
                          variant="body2"
                          color="primary"
                          style={{ fontSize: "14px" }}
                        >
                          Message<span style={{ color: "#ff7d68" }}>*</span>
                        </Typography>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          error={Boolean(touched.comment && errors.comment)}
                          fullWidth
                          helperText={touched.comment && errors.comment}
                          name="comment"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          value={values.comment}
                          multiline
                          rowsMax={10}
                          rows={10}
                          inputProps={{
                            maxLength: 1500,
                          }}
                        />
                        {values.comment.length > 1499 && (
                          <FormHelperText error>
                            Please should not exceed 1500 characters
                          </FormHelperText>
                        )}
                      </Box>
                      <Box mt={2} display="flex">
                        <Button
                          disabled={isSubmitting || value === undefined}
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Submit{isUpdating && <ButtonCircularProgress />}
                        </Button>
                        &nbsp; &nbsp; &nbsp;
                        <Button
                          color="secondary"
                          // size="large"
                          type="submit"
                          variant="contained"
                          onClick={() => history.push("/")}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Paper>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default FeedBack;
